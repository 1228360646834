@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.CookieBanner {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 3.2rem;
  background-color: var(--black);
  z-index: 9999;
  display: flex;
  justify-content: space-between;

  @include mixins.mediaSmall {
    flex-direction: column;
    padding: 2rem 2rem 3.2rem;
  }
}

.CookieBannerButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 0 1.2rem;
  margin-top: 2rem;
}

.CookieBannerButton {
  border-radius: 4px;
  border: 1px solid var(--white);
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 2rem;
  font-size: 1.6rem;

  @include mixins.mediaSmall {
    width: 100%;
  }

  &.CookieBannerButtonAccept {
    background-color: var(--white);
    color: var(--black);
    font-weight: 600;
  }
}

.CookieText {
  color: var(--white);
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8;

  @include mixins.mediaSmall {
    font-size: 1.2rem;
  }
  a {
    color: var(--deepBlue);
    text-decoration: underline;
  }
}
