@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 8rem;
  align-items: center;
  background-color: var(--white);
  padding: 0 4rem;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--exLightGrey);
  z-index: var(--zIndexHeader);
  &[data-disable='true'] {
    pointer-events: none;
  }

  svg {
    cursor: pointer;
  }

  @include mixins.mediaSmall {
    height: 7.2rem;
    padding: 0 2rem;
  }
}

.headerMenu {
  display: flex;
  align-items: center;
  gap: 0 1.6rem;

  @include mixins.mediaSmall {
    gap: 0 1.2rem;
  }
}

.headerUserLoginButton {
  line-height: 1;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 4px;
  width: 9rem;
  height: 3.2rem;
  border: 2px solid var(--blue);
  transition: color var(--durationShort), background-color var(--durationShort);

  &:hover {
    background-color: var(--white);
    color: var(--blue);
  }
}

.headerMypageLink {
  line-height: 1;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 4px;
  border: 2px solid var(--blue);
  transition: color var(--durationShort), background-color var(--durationShort);
  padding: 0.6rem 1.2rem;
  gap: 0.5rem;

  @include mixins.mediaSmall {
    padding: 0.6rem 0.8rem;
  }

  &:hover {
    background-color: var(--white);
    color: var(--blue);

    .headerMypageLinkIcon {
      path {
        fill: var(--blue);
      }
    }
  }
}

.headerMypageLinkIcon {
  display: block;
  width: 2rem;
}

.headerButton {
  display: block;
  width: 2.8rem;

  svg {
    display: block;
    width: 100%;

    &.open {
      path {
        stroke: var(--blue);
        stroke-width: 2px;
        stroke-linecap: 'round';
      }
    }

    &.close {
      path {
        fill: var(--blue);
        fill-rule: 'evenodd';
        clip-rule: 'evenodd';
      }
    }
  }
}
