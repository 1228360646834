@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.logo {
  width: 32rem;
  height: 5rem;

  @include mixins.mediaSmall {
    width: 14rem;
    height: auto;
  }

  > * {
    width: 100% !important;
    height: 100% !important;
  }
}
