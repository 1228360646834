@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.menu {
  position: absolute;
  top: 6rem;
  right: 4rem;
  background-color: var(--white);
  border-radius: 10px;
  z-index: var(--zIndexMenu);
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--lightGrey);
  min-width: 40rem;

  @include mixins.mediaSmall {
    min-width: 30rem;
    top: 6rem;
    right: 2rem;
  }
}

.menuOpen {
  display: block;
}

.menuClose {
  display: none;
}

.menuListItemLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-bottom: 1px solid var(--exLightGrey);
  letter-spacing: 0.05em;

  @include mixins.mediaSmall {
    font-size: 1.4rem;
  }
}

.menuListItemLinkText {
  display: flex;
  align-items: center;
}

.menuListItemLinkTag {
  font-size: 1.1rem;
  background: var(--blue);
  color: var(--white);
  padding: 0rem 0.6rem;
  display: inline-block;
  margin-right: 8px;
  font-style: normal;
  border-radius: 3px;
}

.menuButton {
  padding: 2rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuLogout {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 8px;
  height: 4rem;
  width: 16rem;
  background-color: var(--lightGrey);
}
