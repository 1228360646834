@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.domLoad {
  will-change: opacity;
  opacity: 1;
  transition: opacity .25s;

  &[data-domload='true'] {
    opacity: 0;
  }

  &[data-domload='false'] {
    animation: fade-in .25s forwards 1s;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
