.expandRight {
  width: 2rem;
  height: 2rem;
  display: block;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
