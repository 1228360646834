@use 'sass:math';
@use './mixins.scss';

html {
  backface-visibility: hidden;
  background-color: #fafafa;
  color: var(--black);
  font-feature-settings: 'pkna';
  font-size: mixins.vw(10, mixins.$mediaDefault);
  font-family: var(--fontGothic);
  font-weight: normal;
  letter-spacing: math.div(20, 1000) * 1em;
  line-height: 2;
  scroll-behavior: smooth;

  @include mixins.mediaLarge {
    font-size: 10px;
  }

  @include mixins.mediaSmall {
    font-size: mixins.vw(10, mixins.$mediaSmall);
  }

  @include mixins.mediaExtraSmall {
    font-size: mixins.vw(10, mixins.$mediaExtraSmall);
  }
}

::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  background-color: var(--white);
  display: none;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: var(--blue);
}

::selection {
  color: var(--white);
  background-color: var(--blue);
}

*,
*:before,
*:after {
  backface-visibility: inherit;
  accent-color: var(--blue);
}

*:disabled {
  background-color: var(--lightGrey) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

ul {
  list-style: none;
}

a,
button {
  color: inherit;
  text-decoration: none;
}

canvas,
svg,
picture,
video,
img {
  display: block;
}

img,
svg {
  width: 100%;
  height: auto;
  object-fit: contain;
}

video {
  object-fit: cover;
}

table {
  border-collapse: collapse;
}

.utilsPc {
  display: block;

  @include mixins.mediaSmall {
    display: none;
  }
}

.utilsSp {
  display: none;

  @include mixins.mediaSmall {
    display: block;
  }
}

.-IsScrollActive {
  background-color: var(--blue) !important;
  color: var(--white) !important;
}

.-IsLinkActive {
  color: var(--blue) !important;

  svg {
    path {
      fill: var(--blue);
    }
  }
}
