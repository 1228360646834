@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.footer {
  background-color: #f1f2f7;
  padding: 4rem 0;
  &[data-disable='true'] {
    pointer-events: none;
  }
}

.footerContainer {
  max-width: 104rem;
  margin: 0 auto;

  @include mixins.mediaSmall {
    max-width: initial;
    padding: 0 3.2rem;
  }
}

.footerLogo {
  width: 32rem;
}

.footerMainList {
  margin-top: 3.2rem;

  ul {
    display: flex;
    align-items: center;

    @include mixins.mediaSmall {
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      &:not(:last-of-type) {
        margin-right: 2.4rem;

        @include mixins.mediaSmall {
          margin: 0 0 2rem 0;
        }
      }

      a {
        line-height: 1;
        font-size: 1.6rem;
        color: #5d6272;
        font-weight: 600;
      }
    }
  }
}

.footerSubList {
  margin-top: 1.6rem;

  @include mixins.mediaSmall {
    margin-top: 3.2rem;
  }

  ul {
    display: flex;
    align-items: center;

    @include mixins.mediaSmall {
      flex-wrap: wrap;
    }

    li {
      &:not(:last-of-type) {
        margin-right: 2rem;
      }

      @include mixins.mediaSmall {
        margin-bottom: 0.8rem;
      }

      a {
        line-height: 1;
        font-size: 1.4rem;
        font-weight: 500;
        color: #5d6272;
      }
    }
  }
}

.footerLogoLink {
  margin-top: 4rem;
  @include mixins.mediaSmall {
    margin-top: 2.8rem;
  }

  > a {
    width: 11.2rem;
    display: block;
  }
}

.footerSns {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-top: 1.2rem;

  @include mixins.mediaSmall {
    gap: 2rem;
    margin-top: 1.6rem;
  }

  li {
    a {
      display: block;
      width: auto;
      height: 2rem;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

.footerLogoLinkList {
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  gap: 0 2rem;

  a {
    display: block;
    width: 12rem;

    * {
      width: 100% !important;
    }
  }
}

.footerCopylight {
  line-height: 1;
  font-weight: 300;
  font-size: 1.1rem;
  margin-top: 4rem;
}
