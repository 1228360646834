@use 'src/assets/styles/mixins.scss';

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.loader {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blackTransparent);
  color: var(--white);
  opacity: 0;
  pointer-events: none;
  z-index: var(--zIndexLoader);
  &[data-active='true'] {
    opacity: 1;
    pointer-events: auto;
  }
  &Inner {
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 3.6rem;
    place-items: center;
  }
  &Circle {
    width: 16rem;
    height: 16rem;
    animation: rotate 1.5s linear 0s infinite normal both;
  }
  &Text {
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.8;
    text-align: center;
  }
}

.mailLoader {
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 1.4rem;
  place-items: center;
  max-width: 40%;
  width: 100%;
  border-radius: 8px;
  padding: 5rem 0 6rem;
  background-color: var(--white);
  color: var(--black);
  @include mixins.mediaSmall {
    max-width: calc(100% - 4rem);
    width: 100%;
    padding: 4rem 0 6rem;
  }
  &Head {
    color: var(--blue);
    font-size: 2.4rem;
  }
  &Text {
    font-size: 1.6rem;
    text-align: center;
  }
  &Small {
    display: block;
    color: var(--red);
    font-size: 1.2rem;
    text-align: center;
  }
  &Link {
    display: block;
    border: 2px solid var(--blue);
    border-radius: 0.4rem;
    padding: 0.6rem 3rem;
    font-size: 1.4rem;
    color: var(--white);
    background-color: var(--blue);
    &:hover {
      color: var(--blue);
      background-color: var(--white);
    }
  }
}
