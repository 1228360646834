@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.fixedRegisterBanner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--zIndexFixedRegisterBanner);
  width: 100%;
  height: 7rem;
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 2rem;
  font-weight: 700;
  color: var(--white);
  transition: background-color .25s;
  border-top: 2px solid var(--blue);

  &:hover {
    background-color: var(--white);
    color: var(--blue);
  }
}
