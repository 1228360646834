@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.button {
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: color .25s, background-color .25s;
  border: 2px solid transparent;

  &[data-color='blue'] {
    background: var(--blue);
    color: var(--white);
    border-color: var(--blue);

    &:hover {
      color: var(--blue);
      background-color: var(--white);
    }
  }

  &[data-size='medium'] {
    width: 28rem;
    height: 5.2rem;
    font-size: 1.6rem;

    @include mixins.mediaSmall {
      height: 5.6rem;
      width: 33.5rem;
      font-size: 2rem;
    }
  }

  &[data-size='small'] {
    width: 22rem;
    height: 4.8rem;
    font-size: 1.6rem;
  }

  &[data-size='lg:Small_mq:Large'] {
    width: 22rem;
    height: 4.8rem;
    font-size: 1.6rem;

    @include mixins.mediaSmall {
      width: 28rem;
      height: 5.2rem;
      font-size: 2rem;
    }
  }
}
